import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ButtonModule } from '@ui/button';
import { CardsModule } from '@ui/cards';
import { NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceCardItem } from '@ui/cards';
import { ServiceType, UpsellServiceType } from '@app/services/types/service-type';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { RouterLink } from '@angular/router';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { UiContext } from '@ui/ui.enums';

@Component({
    selector: 'app-upsell-telecom',
    standalone: true,
    imports: [ButtonModule, CardsModule, NgOptimizedImage, TranslateModule, RouterLink],
    template: ` <smvd-ui-service-card
        [title]="'SERVICES.TELECOM.UPSELL.TITLE' | translate"
        [description]="'SERVICES.TELECOM.UPSELL.TEXT' | translate"
        [service]="ServiceCardItem.Telecom"
        [id]="'telecom-card'"
        class="u-margin-bottom-md"
    >
        <ng-container image-content>
            <div class="__image-container w-full h-full flex items-center justify-center">
                <img ngSrc="https://assets.smooved.be/logos/v2/services/telecom/telco-logos.png" width="94" height="93" />
            </div>
        </ng-container>
        <ng-container actions-content>
            <a
                (click)="posthogServicesService.clickUpsellService(serviceType, UpsellServiceType.Telecom)"
                smvd-ui-button
                [context]="UiContext.PrimaryDark"
                [routerLink]="telecomRoute"
                class="u-w-fit-content"
                >{{ 'SERVICES.TELECOM.UPSELL.CTA' | translate }}</a
            >
        </ng-container>
    </smvd-ui-service-card>`,

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsellTelecomComponent {
    @Input() public serviceType: ServiceType;

    protected readonly posthogServicesService = inject(PosthogServicesService);

    public readonly ServiceCardItem = ServiceCardItem;
    public readonly UpsellServiceType = UpsellServiceType;
    public readonly UiContext = UiContext;
    public readonly telecomRoute = `/${Navigation.Telecom}`;
}
