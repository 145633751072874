import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ButtonModule } from '@ui/button';
import { CardsModule } from '@ui/cards';
import { NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceType, UpsellServiceType } from '@app/services/types/service-type';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { ServiceCardItem } from '@ui/cards';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { UiContext } from '@ui/ui.enums';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-upsell-boiler-maintenance',
    standalone: true,
    imports: [ButtonModule, CardsModule, NgOptimizedImage, TranslateModule, RouterLink],
    template: ` <smvd-ui-service-card
        [title]="'SERVICES.BOILER_MAINTENANCE.UPSELL.TITLE' | translate"
        [description]="'SERVICES.BOILER_MAINTENANCE.UPSELL.TEXT' | translate"
        [service]="ServiceCardItem.BoilerMaintenance"
        [id]="'boiler-maintenance-card'"
        class="u-margin-bottom-md"
    >
        <ng-container image-content>
            <div class="__image-container w-full h-full flex items-center justify-center">
                <img ngSrc="https://assets.smooved.be/logos/v2/services/boiler-maintenance/engie.png" width="139" height="84" />
            </div>
        </ng-container>
        <ng-container header-right>
            <div class="rounded-xl text-white whitespace-nowrap u-font-weight-bold u-background-error px-2 py-2px">
                {{ 'SERVICES.BOILER_MAINTENANCE.UPSELL.DISCOUNT' | translate }}
            </div>
        </ng-container>
        <ng-container actions-content>
            <a
                (click)="posthogServicesService.clickUpsellService(serviceType, UpsellServiceType.BoilerMaintenance)"
                smvd-ui-button
                [context]="UiContext.PrimaryDark"
                [routerLink]="boilerMaintenanceRoute"
                class="u-w-fit-content u-margin-top-md"
                >{{ 'DASHBOARD.BOILER_MAINTENANCE.OFFER' | translate }}</a
            >
        </ng-container>
    </smvd-ui-service-card>`,
    styleUrl: './upsell-boiler-maintenance.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsellBoilerMaintenanceComponent {
    @Input() public serviceType: ServiceType;

    protected readonly posthogServicesService = inject(PosthogServicesService);
    public readonly ServiceCardItem = ServiceCardItem;

    public readonly UpsellServiceType = UpsellServiceType;
    public readonly UiContext = UiContext;
    public readonly boilerMaintenanceRoute = `/${Navigation.Services}/${Navigation.BoilerMaintenance}`;
}
