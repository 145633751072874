import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonAppearance, ButtonModule, CardsModule, ServiceCardItem, UiSandbox } from '@smooved/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LineupModule } from '@app/services-components/lineup/lineup.module';
import { ServicesState } from '@app/services/state/services.state';
import { Store } from '@ngrx/store';
import { BaseStepContainer } from '@app/services-components/base-step/base-step.container';
import { selectTransactionInfo } from '@app/services/state/services.selectors';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { ServiceCardConfig, UpsellServiceType } from '@app/services/types/service-type';
import { UpsellTelecomComponent } from '@app/services-components/upsell-telecom/upsell-telecom.component';
import { UpsellBoilerMaintenanceComponent } from '@app/services-components/upsell-boiler-maintenance/upsell-boiler-maintenance.component';

@Component({
    selector: 'app-thanks-container',
    template: `
        <ng-container *ngIf="{ transaction: transaction$ | async } as vm">
            <app-base-step-container>
                <h3 class="u-margin-bottom-md">{{ 'SERVICES.THANKS.TITLE' | translate }}</h3>
                <p class="u-margin-bottom-lg">
                    {{ 'SERVICES.THANKS.DESCRIPTION' | translate }} <strong>{{ vm.transaction?.user?.email }}.</strong>
                </p>
                <h6 class="u-margin-bottom-sm-md">{{ 'SERVICES.THANKS.UPSELL.TITLE' | translate }}</h6>

                @for (upsellService of upsellServices; track upsellService.upsellServiceType) {
                    @switch (upsellService.upsellServiceType) {
                        @case (UpsellServiceType.Telecom) {
                            <app-upsell-telecom [serviceType]="serviceType"></app-upsell-telecom>
                        }
                        @case (UpsellServiceType.BoilerMaintenance) {
                            <app-upsell-boiler-maintenance [serviceType]="serviceType"></app-upsell-boiler-maintenance>
                        }
                    }
                }
                <div class="u-flex-row u-flex-justify-content-end">
                    <button
                        smvd-ui-button
                        (click)="posthogServicesService.clickDone(serviceType)"
                        [context]="uiContext.Text"
                        routerLink="/"
                    >
                        {{ 'COMMON.READY' | translate }}
                    </button>
                </div>
            </app-base-step-container>
        </ng-container>
    `,
    styleUrls: ['./thanks.container.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ButtonModule,
        LineupModule,
        RouterModule,
        BaseStepContainer,
        CardsModule,
        UpsellTelecomComponent,
        UpsellBoilerMaintenanceComponent,
    ],
})
export class ThanksContainer extends BaseStepContainer {
    @Input() public upsellServices: ServiceCardConfig[];

    public transaction$ = this.store.select(selectTransactionInfo);

    protected readonly service = ServiceCardItem;

    constructor(
        private readonly store: Store<ServicesState>,
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        public readonly uiSandbox: UiSandbox,
        public readonly posthogServicesService: PosthogServicesService
    ) {
        super(route, router, uiSandbox);
    }

    protected readonly ButtonAppearance = ButtonAppearance;
    protected readonly UpsellServiceType = UpsellServiceType;
}
